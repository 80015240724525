.pitari {
  font-size: 0;
  background: #ddd;
  // height: 50px;

  * {
    font-size: 0;
  }

  img {
    display: block;
    width: auto !important;
    margin: 0 auto;
    max-height: 50px;

    @media (max-width: 1439px) {
      width: 100% !important;
      max-height: none;
    }
  }
}
